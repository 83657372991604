.swiper-button-prev,
.swiper-button-next {
    color: #B49249;
    height: 2px !important;
    width: 2px !important;
    font-size: 10px !important; 
    padding: 10px !important;
}


.swiper-pagination-bullet-active {
    background-color: #B49249 !important;
}

